import React from 'react';
import logo from './logo.svg';
import './App.css';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";


let loggedIn = false;

const requestLogIn = (userObj:any) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  axios.post(`https://api.lifenotes.co/aunth`,
  {
    userObj,
    token: urlParams.get("token")
  })
          .then((response) => {
            //setChaptersData(response.data.data.filter((chapter:any) => chapter.number !== "intro"));
            //setNavTitle(bookName);

            console.log("chapters: ",response.data);

            if (response.data.ok) {
              console.log("redirect");
              loggedIn = true;
              window.location.href = "lifenotes.log.in://other/parameters/here";
            } else {
              console.log("not ok")
            }
          })
 };

 

function App() {
  return (
    <div className="App">
      {
        loggedIn ?
        <p></p>
        :
        <>
        <div className="login-logo">
          <img style={{width: "50%"}} src="logo.svg" alt="LifeNotes logo" />
        </div>
      <GoogleOAuthProvider clientId="974903784038-tlcuj2hfljrb625fqlfjqa3k8iqjpd3i.apps.googleusercontent.com">
          <div style={{width: '100%', textAlign: 'center'}}>
          <div style={{width: 'fit-content', display: 'inline-block'}}>
          <GoogleLogin
  onSuccess={credentialResponse => {
    if (credentialResponse && credentialResponse.clientId && credentialResponse.credential) {
      
      
      const userObj = jwtDecode(credentialResponse.credential);
      console.log({userObj})
      requestLogIn(userObj)
    }
    console.log(credentialResponse);
     
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
          </div>
          </div>
          
        
        </GoogleOAuthProvider>
        <p style={{padding: "10px"}}>
          Select the Google account you want to sync with LifeNotes.
        </p>
        </>
        
      }
      
    </div>
  );
}

export default App;
